// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/images/import/excell.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("@/assets/images/import/excel1.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".first-step[data-v-20a6b4c0]{margin:0 20%}.import-tips[data-v-20a6b4c0]{margin-top:29px;text-align:left;height:65px;font-size:14px;color:#595959;line-height:24px}.import-tips .tips1[data-v-20a6b4c0]{display:-webkit-box;display:-webkit-flex;display:-moz-box;display:flex;-webkit-box-align:center;-webkit-align-items:center;-moz-box-align:center;align-items:center;font-weight:700}.help-img[data-v-20a6b4c0]{width:16px;height:16px;margin-right:5px}.download-img[data-v-20a6b4c0]{width:11px;height:13px;margin-right:5px}.active-excel[data-v-20a6b4c0]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.inactive-excel[data-v-20a6b4c0]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}.excel-wrapper[data-v-20a6b4c0]{position:relative;margin:40px 0 29px 0;width:100%;height:170px;background-color:#f5f7fa;background-repeat:no-repeat;background-size:20%;background-position:center 20%}.excel-wrapper .fileName[data-v-20a6b4c0]{position:absolute;bottom:28px;left:50%;-webkit-transform:translate(-50%);-moz-transform:translate(-50%);-ms-transform:translate(-50%);transform:translate(-50%);white-space:nowrap;font-size:18px;font-weight:700;color:#595959}.excel-wrapper .cancel-img[data-v-20a6b4c0]{position:absolute;top:10px;right:10px;width:20px;height:20px;cursor:pointer;font-size:20px;color:#c1cedb}.excel-wrapper .cancel-img[data-v-20a6b4c0]:hover{color:#faafaf}.upload-wrapper[data-v-20a6b4c0]{display:-webkit-box;display:-webkit-flex;display:-moz-box;display:flex;margin-bottom:60px}.upload-wrapper .upload-tips[data-v-20a6b4c0]{margin-left:16px;font-size:12px;color:#a6a6a6;line-height:22px}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
